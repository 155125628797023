import * as Yup from 'yup';

// Types
export const WHOLESALE = 'wholesale';
export const RETAIL = 'retail';
export const EXISTINGROWID = 'existingRowId';
export const SELECTED_FOLDER = 'selectedFolder';
export const SELECTED_CLIENT = 'client';
export const SELECTED_DEAL = 'deal';


//COMPLETED BY
export const COMPLETED_BY =  'completedBy'
export const RISK_ADVISER =  'riskAdviser'

// General Questions
export const GENERAL_QUESTIONS = {
  CLIENT: 'client',
  DATE: 'date',
  RA: 'ra',
  NOTES: 'notes',
  TYPE: 'type',
  LOA_COMPLETED: 'loaCompleted',
  INSURANCE_REVIEW_COMPLETED_DOCUMENTED: 'insuranceReviewCompletedDocumented',
  QUOTE_PRESENTED: 'quotePresented',
  HOLD_COVERS: 'holdCovers',
  FINAL_REPORT_OUTLINING_COVERS: 'finalReportOutliningCovers',
  FILING_COMPLETED: 'filingCompleted',
  TASKS_SET_TO_FOLLOW_UP_ANY_RISK_MANAGEMENT: 'tasksSetToFollowUpAnyRiskManagement',

};

// Wholesale Questions
export const WHOLESALE_QUESTIONS = {
  OPT_RETAIL: 'optRetail',
  NATURE_AND_SCOPE_ADVICE_OUTLINED: 'natureAndScopeAdviceOutlined',
  TERMS_OF_ENGAGEMENT_AGREED: 'termsOfEngagementAgreed',
  ADDITIONAL_SERVICES_DISCUSSED: 'additionalServicesDiscussed',
  AUTHORITY_TO_PROCEED: 'authorityToProceed',
  ADDITIONAL_SERVICES_ARRANGED: 'additionalServicesArranged',
};

// Retail Questions
export const RETAIL_QUESTIONS = {
  NEEDS_OBJECTIVES_ESTABLISHED: 'needsObjectivesEstablished',
  FINANCIAL_LITERACY_DISCUSSED: 'financialLiteracyDiscussed',
  VULNERABLE_CLIENTS_DISCUSSED: 'vulnerableClientsDiscussed',
  NATURE_SCOPE_ADVICE_OUTLINED_INCLUDING_VULNERABILITY: 'natureScopeAdviceOutlinedIncludingVulnerability',
  UNINSURED_RISKS_REVIEWED: 'uninsuredRisksReviewed',
  RESEARCH_DOCUMENTED: 'researchDocumented',
  SOA_PREPARED: 'soaPrepared',
  APPLICANT_DECLARATION_COMPLETED: 'applicantDeclarationCompleted',
};

// Labels
export const LABELS = {
  [GENERAL_QUESTIONS.NOTES]: 'Notes',
  [GENERAL_QUESTIONS.LOA_COMPLETED]: 'LOA Completed',
  [GENERAL_QUESTIONS.INSURANCE_REVIEW_COMPLETED_DOCUMENTED]: 'Insurance Review completed with client and documented (information gathering)',
  [GENERAL_QUESTIONS.QUOTE_PRESENTED]: 'Quote Presented (including SOA)',
  [GENERAL_QUESTIONS.HOLD_COVERS]: 'Hold Covers',
  [GENERAL_QUESTIONS.FINAL_REPORT_OUTLINING_COVERS]: 'Final Report Outlining Covers',
  [GENERAL_QUESTIONS.FILING_COMPLETED]: 'Filing Completed',
  [GENERAL_QUESTIONS.TASKS_SET_TO_FOLLOW_UP_ANY_RISK_MANAGEMENT]: 'Tasks set to follow up any risk management or agreed reviews on covers',
  [GENERAL_QUESTIONS.DATE]: 'Date',
  [GENERAL_QUESTIONS.CLIENT]: 'Client Name',
  [GENERAL_QUESTIONS.RA]: 'RA',

  [WHOLESALE_QUESTIONS.OPT_RETAIL]: 'Opt into Retail Discussed',
  [WHOLESALE_QUESTIONS.NATURE_AND_SCOPE_ADVICE_OUTLINED]: 'Nature and Scope of Advice Outlined',
  [WHOLESALE_QUESTIONS.TERMS_OF_ENGAGEMENT_AGREED]: 'Terms of Engagement Agreed',
  [WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_DISCUSSED]: 'Additional Services Discussed',
  [WHOLESALE_QUESTIONS.AUTHORITY_TO_PROCEED]: 'Authority to Proceed',
  [WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_ARRANGED]: 'Additional Services Arranged',

  [RETAIL_QUESTIONS.NEEDS_OBJECTIVES_ESTABLISHED]: 'Needs & Objectives Established',
  [RETAIL_QUESTIONS.FINANCIAL_LITERACY_DISCUSSED]: 'Financial Literacy Discussed',
  [RETAIL_QUESTIONS.VULNERABLE_CLIENTS_DISCUSSED]: 'Vulnerable Clients Discussed',
  [RETAIL_QUESTIONS.NATURE_SCOPE_ADVICE_OUTLINED_INCLUDING_VULNERABILITY]: 'Nature & Scope of Advice outlined including any changes made due to vulnerability',
  [RETAIL_QUESTIONS.UNINSURED_RISKS_REVIEWED]: 'Uninsured Risks Reviewed',
  [RETAIL_QUESTIONS.RESEARCH_DOCUMENTED]: 'Research Documented',
  [RETAIL_QUESTIONS.SOA_PREPARED]: 'SOA Prepared',
  [RETAIL_QUESTIONS.APPLICANT_DECLARATION_COMPLETED]: 'Applicant Declaration Completed giving authority to proceed noting any changes to Advice',
};


export const COMPLETE_WHOLESALE = [
  WHOLESALE_QUESTIONS.OPT_RETAIL,
  WHOLESALE_QUESTIONS.NATURE_AND_SCOPE_ADVICE_OUTLINED,
  WHOLESALE_QUESTIONS.TERMS_OF_ENGAGEMENT_AGREED,
  GENERAL_QUESTIONS.INSURANCE_REVIEW_COMPLETED_DOCUMENTED,
  WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_DISCUSSED,
  GENERAL_QUESTIONS.LOA_COMPLETED,
  GENERAL_QUESTIONS.QUOTE_PRESENTED,
  WHOLESALE_QUESTIONS.AUTHORITY_TO_PROCEED,
  GENERAL_QUESTIONS.HOLD_COVERS,
  GENERAL_QUESTIONS.FINAL_REPORT_OUTLINING_COVERS,
  GENERAL_QUESTIONS.FILING_COMPLETED,
  GENERAL_QUESTIONS.TASKS_SET_TO_FOLLOW_UP_ANY_RISK_MANAGEMENT,
  WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_ARRANGED
];

export const COMPLETE_RETAIL = [
  RETAIL_QUESTIONS.NEEDS_OBJECTIVES_ESTABLISHED,
  RETAIL_QUESTIONS.FINANCIAL_LITERACY_DISCUSSED,
  RETAIL_QUESTIONS.VULNERABLE_CLIENTS_DISCUSSED,
  RETAIL_QUESTIONS.NATURE_SCOPE_ADVICE_OUTLINED_INCLUDING_VULNERABILITY,
  GENERAL_QUESTIONS.LOA_COMPLETED,
  GENERAL_QUESTIONS.INSURANCE_REVIEW_COMPLETED_DOCUMENTED,
  RETAIL_QUESTIONS.UNINSURED_RISKS_REVIEWED,
  RETAIL_QUESTIONS.RESEARCH_DOCUMENTED,
  RETAIL_QUESTIONS.SOA_PREPARED,
  GENERAL_QUESTIONS.QUOTE_PRESENTED,
  GENERAL_QUESTIONS.HOLD_COVERS,
  RETAIL_QUESTIONS.APPLICANT_DECLARATION_COMPLETED,
  GENERAL_QUESTIONS.FINAL_REPORT_OUTLINING_COVERS,
  GENERAL_QUESTIONS.FILING_COMPLETED,
  GENERAL_QUESTIONS.TASKS_SET_TO_FOLLOW_UP_ANY_RISK_MANAGEMENT,
];


// Initial Values
export const initialValues = {
  // General Questions
  [GENERAL_QUESTIONS.NOTES]: "",
  [GENERAL_QUESTIONS.TYPE]: "wholesale",
  [GENERAL_QUESTIONS.LOA_COMPLETED]: false,
  [GENERAL_QUESTIONS.INSURANCE_REVIEW_COMPLETED_DOCUMENTED]: false,
  [GENERAL_QUESTIONS.QUOTE_PRESENTED]: false,
  [GENERAL_QUESTIONS.HOLD_COVERS]: false,
  [GENERAL_QUESTIONS.FINAL_REPORT_OUTLINING_COVERS]: false,
  [GENERAL_QUESTIONS.FILING_COMPLETED]: false,
  [GENERAL_QUESTIONS.TASKS_SET_TO_FOLLOW_UP_ANY_RISK_MANAGEMENT]: false,
  [GENERAL_QUESTIONS.DATE]: '',
  [GENERAL_QUESTIONS.CLIENT]: {},
  [GENERAL_QUESTIONS.RA]: false,
  [COMPLETED_BY]: {},
  [RISK_ADVISER]: {},
  [SELECTED_FOLDER]: "",
  [SELECTED_CLIENT]: {},
  [SELECTED_DEAL]: {},

  // Wholesale Questions
  [WHOLESALE_QUESTIONS.OPT_RETAIL]: false,
  [WHOLESALE_QUESTIONS.NATURE_AND_SCOPE_ADVICE_OUTLINED]: false,
  [WHOLESALE_QUESTIONS.TERMS_OF_ENGAGEMENT_AGREED]: false,
  [WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_DISCUSSED]: false,
  [WHOLESALE_QUESTIONS.AUTHORITY_TO_PROCEED]: false,
  [WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_ARRANGED]: false,

  // Retail Questions
  [RETAIL_QUESTIONS.NEEDS_OBJECTIVES_ESTABLISHED]: false,
  [RETAIL_QUESTIONS.FINANCIAL_LITERACY_DISCUSSED]: false,
  [RETAIL_QUESTIONS.VULNERABLE_CLIENTS_DISCUSSED]: false,
  [RETAIL_QUESTIONS.NATURE_SCOPE_ADVICE_OUTLINED_INCLUDING_VULNERABILITY]: false,
  [RETAIL_QUESTIONS.UNINSURED_RISKS_REVIEWED]: false,
  [RETAIL_QUESTIONS.RESEARCH_DOCUMENTED]: false,
  [RETAIL_QUESTIONS.SOA_PREPARED]: false,
  [RETAIL_QUESTIONS.APPLICANT_DECLARATION_COMPLETED]: false,
  [EXISTINGROWID]: ''
};

export const validationSchema = Yup.object().shape({
  [SELECTED_DEAL]: Yup.object().nullable(false).required('Please select a deal')
});
