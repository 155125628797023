import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import DynamicsWebApi from "dynamics-web-api";
import {
  Button,
  Form,
  Dropdown,
  Container,
  Header,
  Divider,
  Segment,
  Message,
  Label,
} from "semantic-ui-react";
import { ClientSearch } from "./ClientSearch";
import { mkey, functionSite, clientSite, sharepointSite, noValueObject } from "../constants";
import axios from "axios";
import {
  businessLinesSchema,
  relationshipSchema,
  clientSchema,
  dealOwnersSchema,
  individualSchema,
} from "../tableSchema";
import {
  businessSections,
  busLineBusinessSections,
  referralTypesObject,
  asbDivisions,
  asbValues,
  asbCommentaries,
  dealProbabilityObject,
} from "../data";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { sendTeamsMessage } from "../teams";
import { useDataContext } from "./context/dataContext";

export const NewClientInput = () => {
  const { instance, accounts } = useMsal();
  const { referralList, asbValueList, asbDivisionList, asbCommentaryList } = useDataContext();
  // Client
  const [selectedClient, selectClient] = useState({});
  const [clientName, setClientName] = useState("");
  const [physicalAddress, setPhysicalAddress] = useState("");

  // Main contact
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [advisers, setAdvisers] = useState([]);
  const [selectedAdviser, setSelectedAdviser] = useState({});

  // Other info
  const [otherInfo, setOtherInfo] = useState("");

  // Drop downs
  const [businessSection, setBusinessSection] = useState({
    value: "",
    key: "",
  });
  const [referralType, setReferralType] = useState({ value: "", key: "" });
  const [dealOwner, setDealOwner] = useState({
    value: "",
    key: "",
    businessSection: "",
    email: "",
    pipedrive: "",
  });
  const [dealProbability, setDealProbability] = useState({
    value: "",
    key: "",
  });

  // Deal owners
  const [dealOwners, setDealOwners] = useState([]);

  // ASB
  const [asbDivision, setAsbDivision] = useState({ value: "", key: "" });
  const [asbCommentary, setAsbCommentary] = useState({ value: "", key: "" });
  const [asbValue, setAsbValue] = useState({ value: "", key: "" });
  const [asbClientManager, setAsbClientManager] = useState("");

  // Validation
  const [validCompany, setValidCompany] = useState(true);
  const [validBusinessSection, setValidBusinessSection] = useState(true);

  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhone, setValidPhone] = useState(true);

  const [validReferralType, setValidReferralType] = useState(true);
  const [validDealOwner, setValidDealOwner] = useState(true);
  const [validDealProbability, setValidDealProbability] = useState(true);

  // ASB Validation
  const [validASBDivision, setValidASBDivision] = useState(true);
  const [validASBValue, setValidASBValue] = useState(true);
  const [validASBCommentary, setValidASBCommentary] = useState(true);
  const [validASBClientManager, setValidASBClientManager] = useState(true);

  // Form validation
  const [formState, setFormState] = useState("");

  // Form loading
  const [loading, setLoading] = useState(false);
  const [loadingAdvisers, setLoadingAdvisers] = useState(false);

  const referralTypes = Object.keys(referralList).map((key) => ({
    key: referralList[key],
    value: key,
    text: key,
  }));

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  const fetchPipedriveDealFields = async (listName, callback) => {
    try {
      const getAdvisers = `${functionSite}GetAllDealFields?code=${mkey}`;
      const response = await axios.get(getAdvisers);
      console.log("here are the deal fields: ", response.data.data);
      const referral = response.data.data.find(
        (item) => item.name === listName
      );
      if (referral && referral.options) {
        const options = referral.options.map((option) => {
          return {
            id: option.id,
            label: option.label,
          };
        });
        const sortedOptions = options.sort((a, b) => {
          const nameA = a.label.toUpperCase();
          const nameB = b.label.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        callback(sortedOptions);
      }



    } catch (error) {
      console.error("Error fetching deal fields: ", error);
    }
  };

  useEffect(() => {
    fetchPipedriveDealFields("BDM Name (Internal DB Risk Adviser)", setAdvisers)
  }, []);





  useEffect(() => {
    const fetchDealOwners = async () => {
      setLoadingAdvisers(true);
      var request = {
        collection: dealOwnersSchema.plural,
        filter: `${dealOwnersSchema.business_section} eq ${businessSection.key}`,
      };
      console.log(request);

      dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          var records = response.value;
          console.log(records);
          setDealOwners(records);
          if (
            dealOwner.value.length > 0 &&
            dealOwner.businessSection !== businessSection.value
          ) {
            setDealOwner({
              value: "",
              key: "",
              businessSection: "",
              email: "",
              pipedrive: "",
            });
          }
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
      setLoadingAdvisers(false);
    };
    if (businessSection.value.length > 0) {
      fetchDealOwners();
    } else {
      setDealOwners([]);
    }
  }, [businessSection]);

  const createFolder = async (companyName) => {
    var folder;

    const replacedCompanyName = companyName.replace(/[()\/.,]/g, "");

    folder = await instance
      .acquireTokenSilent({
        scopes: [`${sharepointSite}/.default`],
        account: accounts[0],
      })
      .then(async (response) => {
        const headers = {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${response.accessToken}`,
        };
        try {
          const response_1 = await axios.post(
            `${clientSite}/_api/contextinfo`,
            null,
            {
              headers: headers,
            }
          );
          const formDigestValue =
            response_1.data.d.GetContextWebInformation.FormDigestValue;
          try {
            const response_2 = await axios.post(
              `${clientSite}/_api/web/folders`,
              {
                __metadata: {
                  type: "SP.Folder",
                },
                ServerRelativeUrl: `/sites/Clients/Prospects/${replacedCompanyName}`,
              },
              {
                headers: {
                  ...headers,
                  "X-RequestDigest": formDigestValue,
                },
              }
            );
            console.log("Folder created successfully", response_2);
            return `${sharepointSite}${response_2.data.d.ServerRelativeUrl}`;
          } catch (error) {
            console.error("Error creating folder", error);
          }
        } catch (error_1) {
          console.error("Error retrieving form digest value", error_1);
        }
      })
      .catch((error) => {
        console.error("Error retrieving access token", error);
      });
    return folder;
  };

  const createListItem = async (companyName, folderUrl, pipedriveID) => {
    const replacedCompanyName = companyName.replace(/[()\/.,]/g, "");

    instance
      .acquireTokenSilent({
        scopes: [`${sharepointSite}/.default`],
        account: accounts[0],
      })
      .then((response) => {
        // console.log(`Bearer ${response.accessToken}`);
        const headers = {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${response.accessToken}`,
        };
        axios
          .post(`${clientSite}/_api/contextinfo`, null, {
            headers: headers,
          })
          .then(async (response) => {
            const formDigestValue =
              response.data.d.GetContextWebInformation.FormDigestValue;

            // Check if the item already exists in the list
            await axios
              .get(
                `${clientSite}/_api/web/lists/getbytitle('Clients')/items?$filter=Pipedrive_Reference eq '${pipedriveID}'`,
                {
                  headers: {
                    ...headers,
                    "X-RequestDigest": formDigestValue,
                  },
                }
              )
              .then(async (response) => {
                // console.log("search item response", response.data);
                // console.log('Here is the item response: ', response.data.d.results[0]);
                if (response.data.d.results.length > 0) {
                  console.log(
                    "The item already exists",
                    response.data.d.results[0].Id
                  );
                } else {
                  // Create the item
                  await axios
                    .post(
                      `${clientSite}/_api/web/lists/getbytitle('Clients')/items`,
                      {
                        __metadata: {
                          type: "SP.Data.ClientsListItem",
                        },
                        Title: replacedCompanyName,
                        ClientURL: folderUrl,
                        Pipedrive_Reference: `${pipedriveID}`,
                        ContentTypeId:
                          "0x01003F1EB5F5FE957A4D978AB70D89E49B65020002D77D95BF883445AFF79835EDB0D0B6",
                      },
                      {
                        headers: {
                          ...headers,
                          "X-RequestDigest": formDigestValue,
                        },
                      }
                    )
                    .then((response) => {
                      console.log("Item created successfully", response);
                    })
                    .catch((error) => {
                      console.error("Error creating item", error);
                      sendTeamsMessage(
                        instance,
                        accounts,
                        true,
                        `Error creating item in New opp loader
                            for <br> client: ${clientName} <br> error: ${error.Message}`
                      );
                    });
                }
              })
              .catch((error) => {
                console.error("Error fetching item", error);
              });
          })
          .catch((error) => {
            console.error("Error retrieving form digest value", error);
          });
      })
      .catch((error) => {
        console.error("Error retrieving access token", error);
      });
  };

  const resetFields = () => {
    selectClient({});
    setClientName("");

    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");

    setOtherInfo("");

    setBusinessSection({ value: "", key: "" });
    setReferralType({ value: "", key: "" });
    setDealOwner({
      value: "",
      key: "",
      businessSection: "",
      email: "",
      pipedrive: "",
    });
    setDealProbability({ value: "", key: "" });

    setAsbDivision({ value: "", key: "" });
    setAsbCommentary({ value: "", key: "" });
    setAsbValue({ value: "", key: "" });
    setAsbClientManager("");

    setValidCompany(true);
    setValidBusinessSection(true);

    setValidFirstName(true);
    setValidLastName(true);
    setValidEmail(true);
    setValidPhone(true);

    setValidReferralType(true);
    setValidDealOwner(true);
    setValidDealProbability(true);

    setValidASBDivision(true);
    setValidASBValue(true);
    setValidASBCommentary(true);
    setValidASBClientManager(true);

    setLoading(false);
    setLoadingAdvisers(false);

    setFormState("");
  };

  const validateFields = () => {
    setValidBusinessSection(businessSection.value.length > 0);
    setValidCompany(clientName.trim().length > 0);
    setValidFirstName(firstName.trim().length > 0);
    setValidLastName(lastName.trim().length > 0);
    setValidPhone(phone.trim().length > 7);
    setValidEmail(email.trim().length > 0);
    setValidReferralType(referralType.value.length > 0);
    setValidDealOwner(dealOwner.value.length > 0);
    setValidDealProbability(dealProbability.value.length > 0);

    if (referralType.value === "ASB") {
      setValidASBDivision(asbDivision.value.length > 0);
      setValidASBValue(asbValue.value.length > 0);
      setValidASBCommentary(asbCommentary.value.length > 0);
      setValidASBClientManager(asbClientManager.length > 0);
    }

    if (
      validCompany &&
      validBusinessSection &&
      validFirstName &&
      validLastName &&
      (validPhone || validEmail) &&
      validReferralType &&
      validDealOwner &&
      validDealProbability &&
      (referralType.value !== "ASB" ||
        (validASBClientManager &&
          validASBCommentary &&
          validASBDivision &&
          validASBValue))
    ) {
      return true;
    } else {
      setFormState("error");
      return false;
    }
  };

  const getDealOwnerPipedrive = async () => {
    var getDealOwner = `${functionSite}GetPipedriveUserByEmail?code=${mkey}`;
    var ownerID;
    if (dealOwner.pipedrive == null || dealOwner.pipedrive.length === 0) {
      let response = await axios.get(getDealOwner, { email: dealOwner.email });
      ownerID = response.data.id;

      var keyPair = {};
      keyPair[dealOwnersSchema.pipedrive_id] = `${ownerID}`;
      // dynamicsWebApi.updateSingleProperty(dealOwner.key, "cr0d7_db_dealowners", { cr0d7_pipedriveid: ownerID }).then(function () {
      dynamicsWebApi
        .updateSingleProperty(dealOwner.key, dealOwnersSchema.plural, keyPair)
        .then(function () {
          console.log("successfully updated deal owner row");
        })
        .catch(function (error) {
          console.log("error updating deal owner row", error);
          sendTeamsMessage(
            instance,
            accounts,
            true,
            `error updating error updating deal owner row in New opp loader
                for <br> client:  ${clientName} <br> error: ${error.Message}`
          );
        });
    } else {
      ownerID = dealOwner.pipedrive;
    }
    return parseInt(ownerID);
  };

  //the custom keys in this param are for email and phone number
  const getCompanyPipedrive = async (company, ownerID) => {
    var pipedriveID;
    // if (company.cr749_pipedriveid == null || company.cr749_pipedriveid.length === 0) {
    if (
      company[clientSchema.pipedrive_id] == null ||
      company[clientSchema.pipedrive_id].length === 0
    ) {
      var createOrg = `${functionSite}CreatePipedriveOrganisation?code=${mkey}`;
      var params = {
        "5a026c2a7973de922569a25962dabbf3bea2ad3a": email,
        "677a987f910920a8701ad0308515dc699de0b2b7": phone,
        address: physicalAddress,
        name: clientName,
        owner_id: parseInt(ownerID),
      };
      if (clientSchema.ibroker in company) {
        params["ibroker"] = company[clientSchema.ibroker];
      }
      console.log(params);
      let response = await axios.post(createOrg, {
        ...params,
      });
      console.log(response);
      pipedriveID = response.data.pipedrive_id;

      var keyPair = {};
      keyPair[clientSchema.pipedrive_id] = `${pipedriveID}`;
      // dynamicsWebApi.updateSingleProperty(company.cr749_db_companyid, "cr749_db_companies", { cr749_pipedriveid: `${pipedriveID}` }).then(function () {
      dynamicsWebApi
        .updateSingleProperty(
          company[clientSchema.id],
          clientSchema.plural,
          keyPair
        )
        .then(() => {
          console.log("successfully updated company row");
        })
        .catch((error) => {
          console.log("error updating company row", error);
          sendTeamsMessage(
            instance,
            accounts,
            true,
            `error updating Company row in New opp loader
                for <br> client:  ${clientName} <br> error: ${error.Message}`
          );
        });
    } else {
      pipedriveID = parseInt(company[clientSchema.pipedrive_id]);
    }
    return pipedriveID;
  };

  const getContactPipedrive = async (contact, pipedriveID, ownerID) => {
    var individualPipedriveID;
    // if (contact.cr749_pipedrive == null || contact.cr749_pipedrive.length === 0) {
    if (
      contact[individualSchema.pipedrive_id] == null ||
      contact[individualSchema.pipedrive_id].length === 0
    ) {
      var createPDContact = `${functionSite}CreatePipedriveContact?code=${mkey}`;
      let response = await axios.post(createPDContact, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        owner_id: parseInt(ownerID),
        pipedrive_id: pipedriveID,
      });
      console.log(response.data);
      individualPipedriveID = response.data.individual_id;

      var keyPair = {};
      keyPair[individualSchema.pipedrive_id] = `${individualPipedriveID}`;
      // dynamicsWebApi.updateSingleProperty(contact.cr749_db_individualid, "cr749_db_individuals", { cr749_pipedrive: `${individualPipedriveID}` }).then(function () {
      dynamicsWebApi
        .updateSingleProperty(
          contact[individualSchema.id],
          individualSchema.plural,
          keyPair
        )
        .then(function () {
          console.log("successfully updated individual row");
        })
        .catch(function (error) {
          console.log("error updating individual row", error);
          sendTeamsMessage(
            instance,
            accounts,
            true,
            `error updating individual row in New opp loader
                for client: <br> ${clientName} <br> Individual: ${firstName} ${lastName} <br> error: ${error.Message}`
          );
        });
    } else {
      individualPipedriveID = parseInt(contact[individualSchema.pipedrive_id]);
    }
    return individualPipedriveID;
  };

  const createPipedriveDeal = async (
    ownerID,
    individualPipedriveID,
    pipedriveID
  ) => {
    var dealParams = {
      name: clientName,
      owner_id: ownerID, // pipedrive id of deal owner
      individual_id: individualPipedriveID, // pipedrive id of individual
      pipedrive_id: pipedriveID,
      business_type: businessSection.value,
      origination_source: referralType.key, // e.g. Google, ASB, DB Client Referral
      deal_probability: dealProbability.key, // e.g. Low Probability, 50|50 Probability, High Probability
      bdm_name: selectedAdviser?.id
    };
    if (referralType.value === "ASB") {
      dealParams = {
        ...dealParams,
        asb: {
          division: asbDivision.key,
          value: asbValue.key,
          commentary: asbCommentary.key,
          client_manager: asbClientManager,
        },
      };
    }
    var createDeal = `${functionSite}CreateDealAndActivities?code=${mkey}`;
    let response = await axios.post(createDeal, { ...dealParams });
    return response.data.deal_id;
  };

  const handleSubmit = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Set the form to loading state to stop client faffing around
    if (validateFields()) {
      setLoading(true);
      const company = await createDataverseClient();

      const ownerID = await getDealOwnerPipedrive();
      const pipedriveID = await getCompanyPipedrive(company, ownerID);

      company[clientSchema.pipedrive_id] = `${pipedriveID}`;

      const contact = await createDataverseContact();

      const individualPipedriveID = await getContactPipedrive(
        contact,
        pipedriveID,
        ownerID
      );
      contact[individualPipedriveID.pipedrive_id] = individualPipedriveID;

      await createBusinessLine(company, contact);

      var relationshipExists = await searchCompanyContactRelationships(
        company,
        contact
      );
      console.log(`Existing relationship? ${relationshipExists}`);
      if (!relationshipExists) {
        await createCompanyContactRelationship(company, contact);
      }
      const dealID = await createPipedriveDeal(
        ownerID,
        individualPipedriveID,
        pipedriveID
      );
      var folderURL = await createFolder(clientName);
      console.log(`Created new folder ${folderURL}`);
      await createListItem(clientName, folderURL, pipedriveID);

      var keyPair = {};
      keyPair[clientSchema.sharepoint] = folderURL;
      // dynamicsWebApi.updateSingleProperty(company.cr749_db_companyid, "cr749_db_companies", { cr749_sharepointsite: folderURL }).then(function () {
      await updateDataverseClient(company, keyPair);

      console.log("Successfully loaded new opportunity");
      /// TODO: post message in Teams chat
      sendTeamsMessage(
        instance,
        accounts,
        false,
        `${clientName} has been successfully added as a new lead for ${businessSection.value}`
      );
      resetFields();
      setFormState("success");
    }
  };

  const createDataverseClient = async () => {
    if (clientSchema.client_name in selectedClient) {
      console.log(`Client ${selectedClient[clientSchema.client_name]} exists`);
      return selectedClient;
    } else {
      console.log(`Created new client ${clientName}`);
      return await createCompany(clientName);
    }
  };

  const updateDataverseClient = async (company, keyPair) => {
    console.log(keyPair);
    dynamicsWebApi
      .updateSingleProperty(
        company[clientSchema.id],
        clientSchema.plural,
        keyPair
      )
      .then(function () {
        console.log("successfully update company row");
      })
      .catch(function (error) {
        console.log("error updating company row", error);
      });
  };

  const createDataverseContact = async () => {
    var contacts = await searchContact(firstName, lastName, phone, email);
    if (contacts.length > 0) {
      console.log(`Contact ${firstName} ${lastName} exists`);
      return contacts[0];
    } else {
      console.log(`Created new contact ${firstName} ${lastName}`);
      return await createContact(firstName, lastName, phone, email);
    }
  };

  const searchContact = async (firstName, lastName, phone, email) => {
    try {
      var contacts = [];

      var request = {
        collection: individualSchema.plural,
        filter: `${individualSchema.full_name} eq '${encodeURIComponent(
          firstName.replaceAll("'", "''")
        )} ${encodeURIComponent(lastName.replaceAll("'", "''"))}'`,
      };

      contacts = await dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          return response.value;
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
          return [];
        });

      if (contacts && contacts.length > 0) {
        console.log("here is the contact: ", contacts);

        contacts = contacts.filter((value) => {
          const workEmail = value[individualSchema.work_email];
          return (
            workEmail &&
            workEmail.trim().toLowerCase() === email.trim().toLowerCase()
          );
        });
      }

      return contacts;
    } catch (error) {
      console.error("Error:", error);
      return [];
    }
  };

  const createContact = async (firstName, lastName, phone, email) => {
    var createdContact;
    // var newContact = {
    //     cr749_firstname: firstName,
    //     cr749_lastname: lastName,
    //     cr749_fullname: `${firstName} ${lastName}`
    // };
    var newContact = {};
    newContact[individualSchema.first_name] = firstName;
    newContact[individualSchema.last_name] = lastName;
    newContact[individualSchema.full_name] = `${firstName} ${lastName}`;
    if (phone.length > 0) {
      newContact[individualSchema.phone_number] = phone;
      // newContact["cr749_phonenumber"] = phone;
    }
    if (email.length > 0) {
      newContact[individualSchema.work_email] = email;
      // newContact["cr749_workemail"] = email;
    }
    var request = {
      // collection: "cr749_db_individuals",
      collection: individualSchema.plural,
      entity: newContact,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    createdContact = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        //do something with a record here
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    return createdContact;
  };

  const createCompany = async (companyName) => {
    var company;
    // var newCompany = {
    //     cr749_companyname: companyName,
    // };
    var newCompany = {};
    newCompany[clientSchema.client_name] = companyName;
    // newCompany["cr749_MainContact@odata.bind"] = `/cr749_employeeses(${contact.cr749_employeesid})`;
    var request = {
      // collection: "cr749_db_companies",
      collection: clientSchema.plural,
      entity: newCompany,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    company = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    return company;
  };

  const createBusinessLine = async (company, contact) => {
    var businessLine;
    // var entity = {
    //     cr749_companyname_: company.cr749_companyname,
    //     cr749_businesssection: businessSection.key
    // };
    var entity = {};
    entity[businessLinesSchema.client_name] = company[clientSchema.client_name];
    entity[businessLinesSchema.business_section] =
      busLineBusinessSections[businessSection.value];
    // entity["cr749_MainContact@odata.bind"] = `/cr749_db_individuals(${contact.cr749_db_individualid})`;
    // entity["cr749_Company@odata.bind"] = `/cr749_db_companies(${company.cr749_db_companyid})`;
    entity[`${businessLinesSchema.main_contact}@odata.bind`] = `/${
      individualSchema.plural
    }(${contact[individualSchema.id]})`;
    entity[`${businessLinesSchema.client}@odata.bind`] = `/${
      clientSchema.plural
    }(${company[clientSchema.id]})`;
    var request = {
      // collection: "cr749_db_businesslines",
      collection: businessLinesSchema.plural,
      entity: entity,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    businessLine = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    return businessLine;
  };

  const createCompanyContactRelationship = async (company, contact) => {
    // var entity = {
    //     cr749_name: `${company.cr749_companyname} - ${contact.cr749_fullname}`,
    // };
    var entity = {};
    entity[relationshipSchema.row_name] = `${
      company[clientSchema.client_name]
    } - ${contact[individualSchema.full_name]}`;
    // entity["cr088_Individual@odata.bind"] = `/cr749_db_individuals(${contact.cr749_db_individualid})`;
    // entity["cr088_Client@odata.bind"] = `/cr749_db_companies(${company.cr749_db_companyid})`;
    entity[`${relationshipSchema.individual}@odata.bind`] = `/${
      individualSchema.plural
    }(${contact[individualSchema.id]})`;
    entity[`${relationshipSchema.client}@odata.bind`] = `/${
      clientSchema.plural
    }(${company[clientSchema.id]})`;
    var request = {
      // collection: "cr088_db_client_individual_relationships",
      collection: relationshipSchema.plural,
      entity: entity,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
  };

  const searchCompanyContactRelationships = async (company, contact) => {
    var exists = false;
    // var request = {
    //     collection: "cr088_db_client_individual_relationships",
    //     filter: `_cr088_individual_value eq ${contact.cr749_db_individualid}`
    // }
    var request = {
      collection: relationshipSchema.plural,
      filter: `_${relationshipSchema.individual.toLowerCase()}_value eq ${
        contact[individualSchema.id]
      }`,
    };
    exists = await dynamicsWebApi
      .retrieveMultipleRequest(request)
      .then(function (response) {
        var records = response.value;
        console.log(records);
        if (records.length > 0) {
          // var filtered = records.filter((value) => value["_cr088_client_value"] === company.cr749_db_companyid);
          var filtered = records.filter(
            (value) =>
              value[`_${relationshipSchema.client.toLowerCase()}_value`] ===
              company[clientSchema.id]
          );
          return filtered.length > 0;
        } else {
          return false;
        }
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    return exists;
  };

  const searchBusinessLines = async (clientObject, newBusSection) => {
    if (Object.keys(clientObject).length > 0) {
      // var request = {
      //     collection: "cr749_db_businesslines",
      //     filter: `_cr749_company_value eq ${clientObject.cr749_db_companyid}`
      // }
      var request = {
        collection: businessLinesSchema.plural,
        filter: `_${businessLinesSchema.client.toLowerCase()}_value eq ${
          clientObject[clientSchema.id]
        }`,
      };
      dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          var records = response.value;
          console.log(records);
          if (records.length > 0) {
            // var filtered = records.filter((value) => value.cr749_businesssection === newBusSection.key);
            console.log("business lines", records);
            var filtered = records.filter(
              (value) =>
                value[businessLinesSchema.business_section] ===
                busLineBusinessSections[newBusSection.value]
            );
            console.log(filtered);
            setValidBusinessSection(
              newBusSection.value.length > 0 && filtered.length === 0
            );
          } else {
            setValidBusinessSection(newBusSection.value.length > 0);
          }
          console.log(response.value);
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    } else {
      setValidBusinessSection(newBusSection.value.length > 0);
    }
  };

  const updateSelectedClient = async (clientObject) => {
    // var newName = "cr749_companyname" in clientObject ? clientObject.cr749_companyname : "";
    var newName =
      clientSchema.client_name in clientObject
        ? clientObject[clientSchema.client_name]
        : "";
    setClientName(newName);
    selectClient(clientObject);
    setValidCompany(newName.trim().length > 0);
    searchBusinessLines(clientObject, businessSection);
  };

  return (
    <>
      <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
        <Container text>
          <Header inverted as="h1">
            New Opportunity Loader
            <Header.Subheader>
              Completing this form will create a Prospect in Pipedrive and
              Sharepoint
            </Header.Subheader>
          </Header>
          <Divider inverted />
        </Container>
      </Segment>
      <Container text style={{ padding: "1em 0em" }}>
        <Form
          loading={loading}
          method="post"
          onSubmit={handleSubmit}
          error={formState === "error"}
          success={formState === "success"}
        >
          <Form.Input
            required
            fluid
            label="Company Name"
            type="text"
            placeholder="Enter company name"
            name="companyName"
            disabled={clientSchema.client_name in selectedClient}
            value={clientName}
            onChange={(e) => {
              setClientName(e.target.value);
              selectClient({});
              setValidCompany(e.target.value.trim().length > 0);
            }}
            error={
              !validCompany
                ? {
                    content: "Please enter your company name",
                    pointing: "above",
                  }
                : null
            }
          />
          <ClientSearch
            searchText={clientName}
            selectedClient={selectedClient}
            selectClient={updateSelectedClient}
          />
           <Form.Field>
              <label>
              BDM Name (internal DB Risk Adviser)
              </label>
              <Dropdown
                loading={loadingAdvisers}
                placeholder={
                  advisers.length === 0
                    ? "There are no users available"
                    : "Search for a user"
                }
                selection
                search
                className="dropdown-custom"
                options={advisers.map((adviser) => ({
                  key: adviser.id,
                  value: adviser,
                  text: adviser.label,
                }))}
                value={
                  Object.keys(selectedAdviser).length === 0
                    ? noValueObject
                    : selectedAdviser
                }
                onChange={(e, { value }) => setSelectedAdviser(value)}
                // onClick={onSearchAdvisers}
              />
            </Form.Field>
          <Form.Field
            required
            name="businessSection"
            error={!validBusinessSection}
          >
            <label>Business Section</label>
            <Dropdown
              placeholder="Select a business section"
              fluid
              selection
              value={businessSection.value}
              onChange={(e, { value }) => {
                var newBusSection = {
                  value: value,
                  key: businessSections[value],
                };
                setBusinessSection(newBusSection);
                searchBusinessLines(selectedClient, newBusSection);
              }}
              options={Object.keys(businessSections).map((key) => ({
                key: businessSections[key],
                value: key,
                text: key,
              }))}
            />
            {!validBusinessSection && (
              <Label prompt error pointing>
                {businessSection.value.length > 0
                  ? "There is already existing business for this client with this business section"
                  : "Please select a business section"}
              </Label>
            )}
          </Form.Field>

          <Segment style={{ margin: "1.5em 0em" }}>
            <Header as="h5">Main Contact</Header>
            <Container style={{ margin: "0em 2em" }}>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="First Name"
                  type="text"
                  placeholder="Enter main contact first name"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setValidFirstName(e.target.value.trim().length > 0);
                  }}
                  error={
                    !validFirstName
                      ? {
                          content: "Please enter main contact first name",
                          pointing: "above",
                        }
                      : null
                  }
                  required
                />
                <Form.Input
                  fluid
                  label="Last Name"
                  type="text"
                  placeholder="Enter main contact last name"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setValidLastName(e.target.value.trim().length > 0);
                  }}
                  error={
                    !validLastName
                      ? {
                          content: "Please enter main contact last name",
                          pointing: "above",
                        }
                      : null
                  }
                  required
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="Work Physical Address"
                  type="text"
                  placeholder="Enter relevant physical  address"
                  value={physicalAddress}
                  onChange={(e) => {
                    setPhysicalAddress(e.target.value);
                  }}
                  name="physicaladdress"
                />
                <Form.Input
                  fluid
                  label="Work Email Address †"
                  type="email"
                  placeholder="Enter main contact email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    /// TODO: May need to set up email validation
                    setValidEmail(email.trim().length > 0);
                    setValidPhone(phone.trim().length > 7);
                  }}
                  error={!(validEmail || validPhone)}
                  name="email"
                />
                <Form.Input
                  fluid
                  label="Phone Number †"
                  type="tel"
                  placeholder="Enter main contact phone number"
                  maxLength="17"
                  minLength="7"
                  value={phone}
                  onChange={(e) => {
                    var regex = /[0-9\- ]/;
                    var entries = e.target.value
                      .split("")
                      .filter((e) => regex.test(e));
                    var newPhone = entries.join("");
                    setPhone(newPhone);
                    /// TODO: May need to set up email validation
                    setValidEmail(email.trim().length > 0);
                    setValidPhone(phone.trim().length > 7);
                  }}
                  error={!(validEmail || validPhone)}
                  name="phone"
                />
              </Form.Group>
              <Message
                style={{
                  padding: "0.5833em 0.833em",
                  fontSize: ".857142rem",
                  backgroundColor: "#fff!important",
                  fontWeight: !(validEmail || validPhone) ? 700 : 100,
                }}
                visible={true}
                error={!(validEmail || validPhone)}
                content={"†  Please enter email and/or phone number"}
              />
            </Container>
          </Segment>
          <Form.Field required name="referralType" error={!validReferralType}>
            <label>Referral Type</label>
            <Dropdown
              placeholder="Select a referral type"
              fluid
              selection
              value={referralType.value}
              onChange={(e, { value }) => {
                setReferralType({
                  value: value,
                  key: referralList[value],
                });
                setValidReferralType(value != null);
              }}
              options={referralTypes}
            />
            {!validReferralType && (
              <Label prompt error pointing>
                Please select a referral type
              </Label>
            )}
          </Form.Field>
          <Segment
            hidden={!(referralType.value === "ASB")}
            style={{ margin: "1.5em 0em" }}
          >
            <Header as="h5">ASB</Header>
            <Container style={{ margin: "0em 2em" }}>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="Client Manager"
                  type="text"
                  placeholder="Enter ASB Client Manager full name"
                  name="asbClientManager"
                  value={asbClientManager}
                  onChange={(e) => {
                    setAsbClientManager(e.target.value);
                    setValidASBClientManager(e.target.value.trim().length > 0);
                  }}
                  error={
                    !validASBClientManager
                      ? {
                          content: "Please enter ASB Client Manager full name",
                          pointing: "above",
                        }
                      : null
                  }
                  required={referralType.value === "ASB"}
                />
                <Form.Field
                  required={referralType.value === "ASB"}
                  name="asbDivision"
                  error={!validASBDivision}
                >
                  <label>ASB Division</label>
                  <Dropdown
                    placeholder="Select an ASB division"
                    fluid
                    selection
                    value={asbDivision.value}
                    onChange={(e, { value }) => {
                      setAsbDivision({
                        value: value,
                        key: asbDivisionList[value],
                      });
                      setValidASBDivision(value != null);
                    }}
                    options={Object.keys(asbDivisionList).map((key) => ({
                      key: asbDivisionList[key],
                      value: key,
                      text: key,
                    }))}
                  />
                  {!validASBDivision && (
                    <Label prompt error pointing>
                      Please select a division
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field
                  required={referralType.value === "ASB"}
                  name="asbValue"
                  error={!validASBValue}
                >
                  <label>ASB Value</label>
                  <Dropdown
                    placeholder="Select an ASB value"
                    fluid
                    selection
                    value={asbValue.value}
                    onChange={(e, { value }) => {
                      setAsbValue({ value: value, key: asbValueList[value] });
                      setValidASBValue(value != null);
                    }}
                    options={Object.keys(asbValueList).map((key) => ({
                      key: asbValueList[key],
                      value: key,
                      text: key,
                    }))}
                  />
                  {!validASBValue && (
                    <Label prompt error pointing>
                      Please select a value
                    </Label>
                  )}
                </Form.Field>
                <Form.Field
                  required={referralType.value === "ASB"}
                  name="asbCommentary"
                  error={!validASBCommentary}
                >
                  <label>ASB Commentary</label>
                  <Dropdown
                    placeholder="Select an ASB commentary"
                    fluid
                    selection
                    value={asbCommentary.value}
                    onChange={(e, { value }) => {
                      console.log('here is the value: ', value);
                      setAsbCommentary({
                        value: value,
                        key: asbCommentaryList[value],
                      });
                      setValidASBCommentary(value != null);
                    }}
                    options={Object.keys(asbCommentaryList).map((key) => ({
                      key: asbCommentaryList[key],
                      value: key,
                      text: key,
                    }))}
                  />
                  {!validASBCommentary && (
                    <Label prompt error pointing>
                      Please select an option
                    </Label>
                  )}
                </Form.Field>
              </Form.Group>
              <Message
                info
                content={"These fields are required for ASB referrals"}
              />
            </Container>
          </Segment>
          <Form.Field required name="dealOwner" error={!validDealOwner}>
            <label>Deal Owner</label>
            <Dropdown
              loading={loadingAdvisers}
              placeholder={
                businessSection.value.length === 0
                  ? "Select business section in order to view deal owners"
                  : dealOwners.length > 0
                  ? "Select a deal owner"
                  : "No deal owners to select; check internet connection"
              }
              disabled={businessSection.value.length === 0}
              fluid
              selection
              value={dealOwner.key}
              onChange={(e, { value }) => {
                var owner = dealOwners.find(
                  (v) => v[dealOwnersSchema.id] === value
                );
                if (owner !== null) {
                  var newOwner = {
                    key: value,
                    value: owner[dealOwnersSchema.owner_name],
                    email: owner[dealOwnersSchema.user_email],
                    pipedrive: owner[dealOwnersSchema.pipedrive_id],
                    businessSection: owner[dealOwnersSchema.business_section],
                  };
                  // The owner is only valid if their business section is the same as the selected businessSection
                  var validOwner =
                    owner[dealOwnersSchema.business_section] ===
                    businessSection.key;
                  setDealOwner(newOwner);
                  setValidDealOwner(value !== null && validOwner);
                  setValidBusinessSection(validOwner);
                }
              }}
              options={
                dealOwners.length > 0
                  ? dealOwners.map((owner, index) => ({
                      key: index,
                      value: owner[dealOwnersSchema.id],
                      text: owner[dealOwnersSchema.owner_name],
                    }))
                  : []
              }
            />
            {!validDealOwner && (
              <Label prompt error pointing>
                Please select a deal owner
              </Label>
            )}
          </Form.Field>
          <Form.Field
            required
            name="dealProbability"
            error={!validDealProbability}
          >
            <label>Deal Probability</label>
            <Dropdown
              placeholder="Select the deal probability"
              fluid
              selection
              value={dealProbability.value}
              onChange={(e, { value }) => {
                setDealProbability({
                  value: value,
                  key: dealProbabilityObject[value],
                });
                setValidDealProbability(value != null);
              }}
              options={Object.keys(dealProbabilityObject).map((key) => ({
                key: dealProbabilityObject[key],
                value: key,
                text: key,
              }))}
            />
            {!validDealProbability && (
              <Label prompt error pointing>
                Please select a deal probability
              </Label>
            )}
          </Form.Field>
          <Form.TextArea
            label="Other Information"
            placeholder="Enter any other information"
            name="otherInfo"
            value={otherInfo}
            onChange={(e) => {
              setOtherInfo(e.target.value);
            }}
          />
          <Message
            success
            header="New opportunity"
            content="Successfully added a new opportunity"
          />
          <Message
            error
            header="Error"
            content="Please ensure the mandatory fields are filled out"
          />
          <Button
            fluid
            loading={loading}
            type="submit"
            content="Create"
            color="green"
            style={{ margin: "2em 0em 0.5em" }}
            onClick={validateFields}
          />
          <Button
            fluid
            disabled={loading}
            basic
            type="reset"
            content="Reset"
            onClick={resetFields}
          />
        </Form>
      </Container>
    </>
  );
};
