import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Form,
  Button,
  Container,
  Header,
  Segment,
  Message,
  Dropdown,
  Checkbox,
  Table,
  Popup,
  Icon,
} from "semantic-ui-react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import DynamicsWebApi from "dynamics-web-api";
import axios from "axios";
import { dynamicsParams, dynamicsWebApiCallback } from "../../../authConfig";
import {
  GENERAL_QUESTIONS,
  RETAIL,
  RETAIL_QUESTIONS,
  validationSchema,
  WHOLESALE,
  WHOLESALE_QUESTIONS,
  initialValues,
  LABELS,
  COMPLETE_RETAIL,
  COMPLETE_WHOLESALE,
  EXISTINGROWID,
  COMPLETED_BY,
  SELECTED_FOLDER,
  SELECTED_DEAL,
  SELECTED_CLIENT,
  RISK_ADVISER,
} from "./validationSchema";
import { clientSchema, newBusinessChecklistSchema } from "../../../tableSchema";
import {
  formatDate,
  functionSite,
  mkey,
  noValueObject,
  sharepointSite,
} from "../../../constants";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { jsPDF } from "jspdf";

export const NewBusinessChecklistForm = () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [advisersList, setAdvisersList] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [receivedClients, hasReceivedClients] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formState, setFormState] = useState("");
  const [loadingAdvisers, setLoadingAdvisers] = useState(false);
  const [client, setClient] = useState({});

  const [foldersRetrieved, setFoldersRetrieved] = useState([]);
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [deals, setDeals] = useState([]);
  const [loadingDeals, setLoadingDeals] = useState(false);

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  const fetchClients = async () => {
    var fetchXml =
      '<fetch mapping="logical">' +
      `<entity name="${clientSchema.name}">` +
      `<attribute name="${clientSchema.client_name}"/>` +
      `<attribute name="${clientSchema.ibroker}"/>` +
      `<attribute name="${clientSchema.pipedrive_id}"/>` +
      `<attribute name="${clientSchema.hubspot_id}"/>` +
      `<attribute name="${clientSchema.id}"/>` +
      "</entity>" +
      "</fetch>";

    dynamicsWebApi
      .executeFetchXmlAll(clientSchema.plural, fetchXml)
      .then(function (response) {
        console.log(response.value);
        var records = response.value.filter(
          (record) => record.cr749_ibrokerid == null
        );
        setClients(records);
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
  };

  useEffect(() => {
    const fetchAdvisers = async () => {
      setLoadingAdvisers(true);
      var getAdvisers = `${functionSite}GetPipedriveAdvisers?code=${mkey}`;
      let response = await axios.get(getAdvisers);
      setAdvisersList(response.data.advisers);
      setLoadingAdvisers(false);
    };
    fetchAdvisers();
  }, []);
  const addNoteToPipedrive = async (note) => {
    try {
      const addNoteUrl = `${functionSite}AddNoteToDeal?code=${mkey}`;
      let response = await axios.post(addNoteUrl, {
        content: note,
        dealId: parseInt(client.cr749_pipedriveid),
      });
      console.log("Here is the response:", response);
    } catch (error) {
      console.error("Error adding note to Pipedrive:", error);
    }
  };
  
  const onSearchFetch = () => {
    if (!receivedClients) {
      fetchClients();
      hasReceivedClients(true);
    }
  };
  //Create New Business Checklist Record

  const createNewBusinessChecklistRecord = async () => {
    setIsLoading(true);
    var newBusiness = {};

    newBusiness[newBusinessChecklistSchema.type] =
      formik.values[GENERAL_QUESTIONS.TYPE] || " ";

    newBusiness[newBusinessChecklistSchema.date] =
      formik.values[GENERAL_QUESTIONS.DATE] || new Date();

    newBusiness[newBusinessChecklistSchema.client] =
      formik.values[GENERAL_QUESTIONS.CLIENT].cr749_companyname;

    newBusiness[newBusinessChecklistSchema.opt_retail] =
      formik.values[WHOLESALE_QUESTIONS.OPT_RETAIL] || false;

    newBusiness[newBusinessChecklistSchema.nature_and_scope_advice_outlined] =
      formik.values[WHOLESALE_QUESTIONS.NATURE_AND_SCOPE_ADVICE_OUTLINED] ||
      false;

    newBusiness[newBusinessChecklistSchema.terms_of_engagment_agreed] =
      formik.values[WHOLESALE_QUESTIONS.TERMS_OF_ENGAGEMENT_AGREED] || false;

    newBusiness[
      newBusinessChecklistSchema.insurance_reviews_completed_documented
    ] =
      formik.values[GENERAL_QUESTIONS.INSURANCE_REVIEW_COMPLETED_DOCUMENTED] ||
      false;

    newBusiness[newBusinessChecklistSchema.additional_services_discussed] =
      formik.values[WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_DISCUSSED] || false;

    newBusiness[newBusinessChecklistSchema.loa_completed] =
      formik.values[GENERAL_QUESTIONS.LOA_COMPLETED] || false;

    newBusiness[newBusinessChecklistSchema.quote_presented] =
      formik.values[GENERAL_QUESTIONS.QUOTE_PRESENTED] || false;

    newBusiness[newBusinessChecklistSchema.authority_to_proceed] =
      formik.values[WHOLESALE_QUESTIONS.AUTHORITY_TO_PROCEED] || false;

    newBusiness[newBusinessChecklistSchema.hold_covers] =
      formik.values[GENERAL_QUESTIONS.HOLD_COVERS] || false;

    newBusiness[newBusinessChecklistSchema.final_report_outlining_covers] =
      formik.values[GENERAL_QUESTIONS.FINAL_REPORT_OUTLINING_COVERS] || false;

    newBusiness[newBusinessChecklistSchema.filing_completed] =
      formik.values[GENERAL_QUESTIONS.FILING_COMPLETED] || false;

    newBusiness[newBusinessChecklistSchema.tasks_set_to_follow_up] =
      formik.values[
        GENERAL_QUESTIONS.TASKS_SET_TO_FOLLOW_UP_ANY_RISK_MANAGEMENT
      ] || false;

    newBusiness[newBusinessChecklistSchema.additional_services_arranged] =
      formik.values[WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_ARRANGED] || false;

    newBusiness[newBusinessChecklistSchema.notes] =
      formik.values[GENERAL_QUESTIONS.NOTES] || " ";

    newBusiness[newBusinessChecklistSchema.needs_objectives_established] =
      formik.values[RETAIL_QUESTIONS.NEEDS_OBJECTIVES_ESTABLISHED] || false;

    newBusiness[newBusinessChecklistSchema.financial_literacy_discussed] =
      formik.values[RETAIL_QUESTIONS.FINANCIAL_LITERACY_DISCUSSED] || false;

    newBusiness[newBusinessChecklistSchema.vulnerable_client_discussed] =
      formik.values[RETAIL_QUESTIONS.VULNERABLE_CLIENTS_DISCUSSED] || false;

    newBusiness[
      newBusinessChecklistSchema.nature_and_scope_advice_including_vulnerability
    ] =
      formik.values[
        RETAIL_QUESTIONS.NATURE_SCOPE_ADVICE_OUTLINED_INCLUDING_VULNERABILITY
      ] || false;

    newBusiness[newBusinessChecklistSchema.uninsured_risks_reviewed] =
      formik.values[RETAIL_QUESTIONS.UNINSURED_RISKS_REVIEWED] || false;

    newBusiness[newBusinessChecklistSchema.research_documented] =
      formik.values[RETAIL_QUESTIONS.RESEARCH_DOCUMENTED] || false;

    newBusiness[newBusinessChecklistSchema.soa_prepared] =
      formik.values[RETAIL_QUESTIONS.SOA_PREPARED] || false;

    newBusiness[newBusinessChecklistSchema.applicant_declaration_completed] =
      formik.values[RETAIL_QUESTIONS.APPLICANT_DECLARATION_COMPLETED] || false;

    console.log("here is the new business: ", newBusiness);

    var request = {
      collection: newBusinessChecklistSchema.plural,
      entity: newBusiness,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    newBusiness = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        var message = `Successfully saved record for ${GENERAL_QUESTIONS.CLIENT}`;
        setSuccessMessage(message);
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    setIsLoading(false);
    return newBusiness;
  };

  const generatePDFAndUpload = async (
    textInput,
    digestValue,
    accessToken,
    url,
    clientType
  ) => {
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
    });

    const pdfWidth = doc.internal.pageSize.width;
    const lineHeight = 6;
    const lines = textInput.split("\n");
    let yPosition = 15;
    lines.forEach((line, index) => {
      const textLines = doc.splitTextToSize(line, pdfWidth - 30);
      if (index === 0) {
        doc.text(textLines, 15, yPosition);
      } else {
        yPosition += lineHeight;
        doc.text(textLines, 15, yPosition);
      }
      yPosition += (textLines.length - 1) * lineHeight;
    });

    const fileContent = doc.output("arraybuffer");
    const fileBlob = new Blob([fileContent], { type: "application/pdf" });

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "X-RequestDigest": digestValue,
    };

    const data = new FormData();
    data.append("file", fileBlob);
    try {
      // Post the file without setting content type ID in FormData
      const fileResponse = await axios.post(url, data, {
        headers,
      });

      console.log("here is the created file reponse: ", fileResponse);
      const serverUrl = fileResponse.data.UniqueId;
      return fileResponse;
    } catch (error) {
      console.log("Error creating file:", error);
    }
  };

  const updateBusinessChecklistRecord = async () => {
    var updatedBusiness = {};

    updatedBusiness[newBusinessChecklistSchema.type] =
      formik.values[GENERAL_QUESTIONS.TYPE] || " ";

    updatedBusiness[newBusinessChecklistSchema.date] =
      formik.values[GENERAL_QUESTIONS.DATE] || new Date();

    updatedBusiness[newBusinessChecklistSchema.opt_retail] =
      formik.values[WHOLESALE_QUESTIONS.OPT_RETAIL] || false;

    updatedBusiness[
      newBusinessChecklistSchema.nature_and_scope_advice_outlined
    ] =
      formik.values[WHOLESALE_QUESTIONS.NATURE_AND_SCOPE_ADVICE_OUTLINED] ||
      false;

    updatedBusiness[newBusinessChecklistSchema.terms_of_engagment_agreed] =
      formik.values[WHOLESALE_QUESTIONS.TERMS_OF_ENGAGEMENT_AGREED] || false;

    updatedBusiness[
      newBusinessChecklistSchema.insurance_reviews_completed_documented
    ] =
      formik.values[GENERAL_QUESTIONS.INSURANCE_REVIEW_COMPLETED_DOCUMENTED] ||
      false;

    updatedBusiness[newBusinessChecklistSchema.additional_services_discussed] =
      formik.values[WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_DISCUSSED] || false;

    updatedBusiness[newBusinessChecklistSchema.loa_completed] =
      formik.values[GENERAL_QUESTIONS.LOA_COMPLETED] || false;

    updatedBusiness[newBusinessChecklistSchema.quote_presented] =
      formik.values[GENERAL_QUESTIONS.QUOTE_PRESENTED] || false;

    updatedBusiness[newBusinessChecklistSchema.authority_to_proceed] =
      formik.values[WHOLESALE_QUESTIONS.AUTHORITY_TO_PROCEED] || false;

    updatedBusiness[newBusinessChecklistSchema.hold_covers] =
      formik.values[GENERAL_QUESTIONS.HOLD_COVERS] || false;

    updatedBusiness[newBusinessChecklistSchema.final_report_outlining_covers] =
      formik.values[GENERAL_QUESTIONS.FINAL_REPORT_OUTLINING_COVERS] || false;

    updatedBusiness[newBusinessChecklistSchema.filing_completed] =
      formik.values[GENERAL_QUESTIONS.FILING_COMPLETED] || false;

    updatedBusiness[newBusinessChecklistSchema.tasks_set_to_follow_up] =
      formik.values[
        GENERAL_QUESTIONS.TASKS_SET_TO_FOLLOW_UP_ANY_RISK_MANAGEMENT
      ] || false;

    updatedBusiness[newBusinessChecklistSchema.additional_services_arranged] =
      formik.values[WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_ARRANGED] || false;

    updatedBusiness[newBusinessChecklistSchema.notes] =
      formik.values[GENERAL_QUESTIONS.NOTES] || " ";

    updatedBusiness[newBusinessChecklistSchema.needs_objectives_established] =
      formik.values[RETAIL_QUESTIONS.NEEDS_OBJECTIVES_ESTABLISHED] || false;

    updatedBusiness[newBusinessChecklistSchema.financial_literacy_discussed] =
      formik.values[RETAIL_QUESTIONS.FINANCIAL_LITERACY_DISCUSSED] || false;

    updatedBusiness[newBusinessChecklistSchema.vulnerable_client_discussed] =
      formik.values[RETAIL_QUESTIONS.VULNERABLE_CLIENTS_DISCUSSED] || false;

    updatedBusiness[
      newBusinessChecklistSchema.nature_and_scope_advice_including_vulnerability
    ] =
      formik.values[
        RETAIL_QUESTIONS.NATURE_SCOPE_ADVICE_OUTLINED_INCLUDING_VULNERABILITY
      ] || false;

    updatedBusiness[newBusinessChecklistSchema.uninsured_risks_reviewed] =
      formik.values[RETAIL_QUESTIONS.UNINSURED_RISKS_REVIEWED] || false;

    updatedBusiness[newBusinessChecklistSchema.research_documented] =
      formik.values[RETAIL_QUESTIONS.RESEARCH_DOCUMENTED] || false;

    updatedBusiness[newBusinessChecklistSchema.soa_prepared] =
      formik.values[RETAIL_QUESTIONS.SOA_PREPARED] || false;

    updatedBusiness[
      newBusinessChecklistSchema.applicant_declaration_completed
    ] =
      formik.values[RETAIL_QUESTIONS.APPLICANT_DECLARATION_COMPLETED] || false;

    console.log("here is the updated business: ", updatedBusiness);

    var request = {
      key: formik.values[EXISTINGROWID],
      collection: newBusinessChecklistSchema.plural,
      entity: updatedBusiness,
      returnRepresentation: true,
    };

    updatedBusiness = await dynamicsWebApi
      .updateRequest(request)
      .then(function (record) {
        var message = `Successfully updated record!`;
        setSuccessMessage(message);
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    return updatedBusiness;
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (formik.values[EXISTINGROWID].length > 0) {
      console.log("row exits: ", formik.values[EXISTINGROWID]);
      const updateRes = await updateBusinessChecklistRecord();
    } else {
      console.log("row does not exists");
      const createNewBusinessRes = await createNewBusinessChecklistRecord();
    }
    setIsLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      type: WHOLESALE,
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      console.log("form values: ", formik.values);
      //checks if selected folder other go by selected client name
      if (formik.values[EXISTINGROWID].length > 0) {
        console.log("row exits: ", formik.values[EXISTINGROWID]);
        const updateRes = await updateBusinessChecklistRecord();
      } else {
        console.log("row does not exists");
        const createNewBusinessRes = await createNewBusinessChecklistRecord();
      }

      const clientFolderName =
        formik.values?.selectedFolder?.length > 0
          ? formik.values.selectedFolder
          : formik.values[GENERAL_QUESTIONS.CLIENT]?.cr749_companyname;
      const note = generateNote();

      if (formik?.values?.deal &&  Object.keys(formik?.values?.deal).length > 0) {
        await addNoteToPipedrive(note);
      }
      await addToSharepoint(note, clientFolderName);
      setFormState("success");
      setIsLoading(false);
    },
  });

  const getSharepointHeaders = async () => {
    return await instance
      .acquireTokenSilent({
        scopes: [`${sharepointSite}/.default`],
        account: accounts[0],
      })
      .then(async (response) => {
        const accessToken = response.accessToken;
        return {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${accessToken}`,
        };
      });
  };

  const getFormDigestValue = async () => {
    const headers = await getSharepointHeaders();
    return await axios
      .post(`${sharepointSite}/_api/contextinfo`, null, {
        headers: headers,
      })
      .then(async (response) => {
        const formDigestValue =
          response.data.d.GetContextWebInformation.FormDigestValue;
        return formDigestValue;
      });
  };

  const checkProspectsFolderURL = async (
    formDigestValue,
    headers,
    accessToken,
    clientName,
    noteWithDate
  ) => {
    const prospectsUrl = `${sharepointSite}/sites/Clients/_api/web/GetFolderByServerRelativeUrl('Prospects/${clientName}')`;

    return await axios
      .get(prospectsUrl, {
        headers: { ...headers, "X-RequestDigest": formDigestValue },
      })
      .then(async (response) => {
        if (response.data.d.toString().length > 0) {
          console.log(response.data.d);
          return await generatePDFAndUpload(
            noteWithDate,
            formDigestValue,
            accessToken,
            `${prospectsUrl}/Files/add(url='${
              formik.values[GENERAL_QUESTIONS.DATE] !== null
                ? `NB Checklist ${clientName} - ${formik.values[
                    GENERAL_QUESTIONS.DATE
                  ]
                    .toLocaleDateString("en-NZ", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                    .replace(/\//g, "-")}`
                : `NB Checklist ${clientName}`
            }.pdf',overwrite=true)`,
            "prospect"
          );
        }
      })
      .catch((err) => console.log(err, "error getting prospect folder"));
  };

  const addToSharepoint = async (noteWithDate, clientName) => {
    console.log("here is the clientName: ", clientName);
    return await instance
      .acquireTokenSilent({
        scopes: [`${sharepointSite}/.default`],
        account: accounts[0],
      })
      .then(async (response) => {
        const accessToken = response.accessToken;
        const headers = {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${accessToken}`,
        };
        // get webcontext and digest value
        return await axios
          .post(`${sharepointSite}/_api/contextinfo`, null, {
            headers: headers,
          })
          .then(async (response) => {
            const formDigestValue =
              response.data.d.GetContextWebInformation.FormDigestValue;
            var fileId;
            if (fileId == null) {
              console.log("Check for prospects URL normal name", clientName);
              fileId = await checkProspectsFolderURL(
                formDigestValue,
                headers,
                accessToken,
                clientName,
                noteWithDate
              );
            }
            if (fileId == null) {
              const encodedClientName = encodeURIComponent(
                clientName.replace(/'/g, "%27").replace(/\//g, "%2F")
              );
              console.log("Check for prospects URL", clientName);
              fileId = await checkProspectsFolderURL(
                formDigestValue,
                headers,
                accessToken,
                encodedClientName,
                noteWithDate
              );
            }
            if (fileId == null) {
              const encodedClientName = encodeURIComponent(clientName);
              console.log("Check for prospects URL encoded", encodedClientName);
              fileId = await checkProspectsFolderURL(
                formDigestValue,
                headers,
                accessToken,
                encodedClientName,
                noteWithDate
              );
            }
          })
          .catch((err) => console.error("error getting digest value", err));
      })
      .catch((err) => {
        console.log("there was an error getting the access token", err);
        var message = `Failed to save note under client ${GENERAL_QUESTIONS.CLIENT}`;
        setErrorMessage(message);
        setFormState("error");
      });
  };

  const handleTypeChange = (value) => {
    formik.setFieldValue(GENERAL_QUESTIONS.TYPE, value);
  };

  const fetchMeetingData = async () => {
    if (formik.values[GENERAL_QUESTIONS.CLIENT]) {
      try {
        setIsLoading(true);
        const request = {
          collection: newBusinessChecklistSchema.plural,
          select: [
            newBusinessChecklistSchema.id,
            newBusinessChecklistSchema.additional_services_discussed,
            newBusinessChecklistSchema.applicant_declaration_completed,
            newBusinessChecklistSchema.additional_services_arranged,
            newBusinessChecklistSchema.authority_to_proceed,
            newBusinessChecklistSchema.date,
            newBusinessChecklistSchema.filing_completed,
            newBusinessChecklistSchema.final_report_outlining_covers,
            newBusinessChecklistSchema.financial_literacy_discussed,
            newBusinessChecklistSchema.hold_covers,
            newBusinessChecklistSchema.insurance_reviews_completed_documented,
            newBusinessChecklistSchema.loa_completed,
            newBusinessChecklistSchema.nature_and_scope_advice_outlined,
            newBusinessChecklistSchema.nature_and_scope_advice_including_vulnerability,
            newBusinessChecklistSchema.needs_objectives_established,
            newBusinessChecklistSchema.notes,
            newBusinessChecklistSchema.opt_retail,
            newBusinessChecklistSchema.quote_presented,
            newBusinessChecklistSchema.ra,
            newBusinessChecklistSchema.research_documented,
            newBusinessChecklistSchema.soa_prepared,
            newBusinessChecklistSchema.tasks_set_to_follow_up,
            newBusinessChecklistSchema.terms_of_engagment_agreed,
            newBusinessChecklistSchema.type,
            newBusinessChecklistSchema.uninsured_risks_reviewed,
            newBusinessChecklistSchema.vulnerable_client_discussed,
          ],
          filter: `${newBusinessChecklistSchema.client} eq '${
            formik.values[GENERAL_QUESTIONS.CLIENT]?.cr749_companyname
          }'`,
          count: true,
        };
        const response = await dynamicsWebApi.retrieveMultipleRequest(request);
        console.log("fetch res: ", response);
        return response;
      } catch (error) {
        console.log("Error fetching client data: ", error);
        return null;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getFolderContents = async (
    folderPath,
    headers,
    formDigestValue,
    clientType,
    clientName
  ) => {
    let clientUrl;
    console.log("getting folder content!");
    clientUrl = `${sharepointSite}/sites/Clients/_api/web/GetFolderByServerRelativeUrl('${folderPath}')/Folders`;
    console.log("here is the client url: ", clientUrl);
    return axios.get(clientUrl, {
      headers: {
        ...headers,
        "X-RequestDigest": formDigestValue,
      },
    });
  };

  const getSharepointYearFoldersInClient = async (
    clientName,
    clientFolder,
    headers,
    formDigestValue,
    clientType
  ) => {
    setLoadingFolders(true);
    const processFolder = async (currentFolderPath, depth) => {
      if (depth >= 2) {
        return [currentFolderPath];
      }

      const foldersResponse = await getFolderContents(
        currentFolderPath,
        headers,
        formDigestValue,
        clientType,
        clientName
      );
      console.log("here are the folders response: ", foldersResponse);
      const folders = foldersResponse.data.d;
      const folderPaths = [currentFolderPath];

      const subfolders = await Promise.all(
        folders.results.map(async (subfolder) => {
          const subfolderPath = `${currentFolderPath}/${subfolder.Name}`;
          const subfolderPaths = await processFolder(subfolderPath, depth + 1);
          return subfolderPaths;
        })
      );

      return folderPaths.concat(...subfolders);
    };

    try {
      let folderPath =
        clientType === "clientSite"
          ? clientFolder
          : `${clientFolder}/${clientName}`;
      console.log("here is the folder path: ", folderPath);
      return await processFolder(folderPath, 0);
    } catch (err) {
      console.error(`Error getting client ${clientName} folder`, err);
      return [];
    } finally {
      setLoadingFolders(false);
    }
  };

  const populateData = async () => {
    setIsLoading(true);
    const currentClientName = {
      [GENERAL_QUESTIONS.CLIENT]: formik.values[GENERAL_QUESTIONS.CLIENT],
    };
    console.log("current client name: ", currentClientName);
    const response = await fetchMeetingData();
    if (response?.value && response.value.length > 0) {
      const values = response.value[0];
      const newValues = {
        [GENERAL_QUESTIONS.QUOTE_PRESENTED]:
          values?.cr0d7_quotepresented ?? false,
        [GENERAL_QUESTIONS.RA]: values?.cr0d7_ra ?? false,
        [RETAIL_QUESTIONS.VULNERABLE_CLIENTS_DISCUSSED]:
          values?.cr0d7_vulnerableclientdiscussed ?? false,
        [GENERAL_QUESTIONS.DATE]: values?.cr0d7_date ?? null,
        [GENERAL_QUESTIONS.FILING_COMPLETED]:
          values?.cr0d7_filingcompleted ?? false,
        [GENERAL_QUESTIONS.FINAL_REPORT_OUTLINING_COVERS]:
          values?.cr0d7_finalreportoutliningcovers ?? false,
        [GENERAL_QUESTIONS.HOLD_COVERS]: values?.cr0d7_holdcovers ?? false,
        [GENERAL_QUESTIONS.INSURANCE_REVIEW_COMPLETED_DOCUMENTED]:
          values?.cr0d7_insurancereviewcompleteddocumented ?? false,
        [GENERAL_QUESTIONS.LOA_COMPLETED]: values?.cr0d7_loacompleted ?? false,
        [GENERAL_QUESTIONS.NOTES]: values?.cr0d7_notes || " ",
        [GENERAL_QUESTIONS.TASKS_SET_TO_FOLLOW_UP_ANY_RISK_MANAGEMENT]:
          values?.cr0d7_taskssettofollowupanyriskmanagement ?? false,
        [GENERAL_QUESTIONS.TYPE]: values?.cr0d7_type ?? "",
        [WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_ARRANGED]:
          values?.cr0d7_additionalservicesarranged ?? false,
        [WHOLESALE_QUESTIONS.ADDITIONAL_SERVICES_DISCUSSED]:
          values?.cr0d7_additionalservicesdiscussed ?? false,
        [WHOLESALE_QUESTIONS.AUTHORITY_TO_PROCEED]:
          values?.cr0d7_authoritytoproceed ?? false,
        [WHOLESALE_QUESTIONS.NATURE_AND_SCOPE_ADVICE_OUTLINED]:
          values?.cr0d7_natureandscopeadviceoutlined ?? false,
        [WHOLESALE_QUESTIONS.OPT_RETAIL]: values?.cr0d7_optretail ?? false,
        [WHOLESALE_QUESTIONS.TERMS_OF_ENGAGEMENT_AGREED]:
          values?.cr0d7_termsofengagementagreed ?? false,
        [RETAIL_QUESTIONS.RESEARCH_DOCUMENTED]:
          values?.cr0d7_researchdocumented ?? false,
        [RETAIL_QUESTIONS.APPLICANT_DECLARATION_COMPLETED]:
          values?.cr0d7_applicantdeclarationcompleted ?? false,
        [RETAIL_QUESTIONS.FINANCIAL_LITERACY_DISCUSSED]:
          values?.cr0d7_financialliteracydiscussed ?? false,
        [RETAIL_QUESTIONS.NATURE_SCOPE_ADVICE_OUTLINED_INCLUDING_VULNERABILITY]:
          values?.cr0d7_natureandscopeofadviceincludingvulnerability ?? false,
        [RETAIL_QUESTIONS.NEEDS_OBJECTIVES_ESTABLISHED]:
          values?.cr0d7_needsobjectivesestablished ?? false,
        [RETAIL_QUESTIONS.UNINSURED_RISKS_REVIEWED]:
          values?.cr0d7_uninsuredrisksreviewed ?? false,
        [EXISTINGROWID]: values?.cr0d7_newbusinesschecklistid || " ",
      };
      formik.setValues(newValues);
    } else {
      formik.setValues({
        ...initialValues,
        ...currentClientName,
      });
    }
    setIsLoading(false);
  };

  //this function can go into constants
  const FormikHelper = (formik) => {
    const getError = (title) =>
      formik.touched[title] && Boolean(formik.errors[title]);
    const getErrorMessage = (title) =>
      formik.touched[title] && formik.errors[title];

    return { getError, getErrorMessage };
  };

  useEffect(() => {
    if (formik.values[GENERAL_QUESTIONS.CLIENT]) {
      populateData();
    }
  }, [formik.values[GENERAL_QUESTIONS.CLIENT]]);

  const handleClientChange = async (value) => {

    formik.setFieldValue(GENERAL_QUESTIONS.CLIENT, value);
    console.log("here is the selected client: ", value);
    setClient(value);
    // Call getSharepointFoldersRecursive when a client is selected
    if (Object.keys(value).length > 0) {
      fetchDeals(value);
      try {
        const headers = await getSharepointHeaders();
        const formDigestValue = await getFormDigestValue();
        const clientName = value.cr749_companyname;

        const folderProspectPaths = await getSharepointYearFoldersInClient(
          clientName,
          "Prospects",
          headers,
          formDigestValue,
          "prospects"
        );
        console.log("folder prospect paths: ", folderProspectPaths);
        const updatedFolderProspectPaths = folderProspectPaths.map((path) => {
          const parts = path.split("/");
          return parts.slice(1).join("/");
        });

        console.log("folders1: ", updatedFolderProspectPaths);
        if (updatedFolderProspectPaths.length >= 1) {
          setFoldersRetrieved(updatedFolderProspectPaths);
        } else {
          console.log("No folders retrieved");
          setFoldersRetrieved([]);
        }
      } catch (error) {
        console.error("Error fetching SharePoint folders:", error);
        setFoldersRetrieved([]);
      }
    }
  };

  const generateNote = () => {
    const selectedOptions = [];

    selectedOptions.push("New Business Checklist: ");

    if (formik.values.type === WHOLESALE) {
      COMPLETE_WHOLESALE.forEach((fieldName) => {
        const selected = formik.values[fieldName] ? "Yes" : "No";
        selectedOptions.push(`${LABELS[fieldName]}: ${selected}`);
      });
    }

    if (formik.values.type === RETAIL) {
      COMPLETE_RETAIL.forEach((fieldName) => {
        const selected = formik.values[fieldName] ? "Yes" : "No";
        selectedOptions.push(`${LABELS[fieldName]}: ${selected}`);
      });
    }

    if (formik.values[GENERAL_QUESTIONS.NOTES]) {
      selectedOptions.push(`\nNote: ${formik.values[GENERAL_QUESTIONS.NOTES]}`);
    }

    if (formik.values.deal) {
      selectedOptions.push(`\nPipedrive deal: ${formik.values.deal?.title}`);
    }


    if (formik.values.completedBy?.name !== null) {
      selectedOptions.push(
        `\nCompleted by: ${formik.values.completedBy?.name ?? "-"}`
      );
    }

    if (
      formik.values.riskAdviser?.name !== null &&
      formik.values.riskAdviser?.name !== formik.values.completedBy?.name
    ) {
      selectedOptions.push(
        `\nRisk adviser: ${formik.values.riskAdviser?.name ?? "-"}`
      );
    }

    return selectedOptions.join("\n");
  };

  const { getError, getErrorMessage } = FormikHelper(formik);

  const onChange = (event, data) => {
    const selectedDate = data.value instanceof Date ? data.value : new Date();
    console.log("here is the selected date: ", selectedDate);

    // const formattedDate = selectedDate
    //   ? selectedDate
    //       .toLocaleDateString("en-NZ", {
    //         year: "numeric",
    //         month: "2-digit",
    //         day: "2-digit",
    //       })
    //       .replace(/\//g, "-")
    //   : "";
    formik.setFieldValue(GENERAL_QUESTIONS.DATE, selectedDate);
  };

  const fetchDeals = async (value) => {
    setLoadingDeals(true);
    console.log("client: ", value);

    var getDeals = `${functionSite}GetPipedriveDealsByOrganisation?code=${mkey}`;
    let response = await axios.get(getDeals, {
      params: {
        pipedrive_id: value.cr749_pipedriveid,
      },
    });
    setDeals(response.data.deals);
    setLoadingDeals(false);
  };

  const TOOLTIP_MESSAGES = {
    [RETAIL_QUESTIONS.NEEDS_OBJECTIVES_ESTABLISHED]:
      "About the business, Reasons for coming to DB, Main objectives in obtaining insurance, Limited Advice, Payment options and Other.",
    [RETAIL_QUESTIONS.RESEARCH_DOCUMENTED]:
      "BI Calculator, LMI Policy Comparison, LMI Risk Coach, Companies Office (including Director search), Insurer Schedules and U/W Submission.",
    [RETAIL_QUESTIONS.SOA_PREPARED]:
      "Recommendations, Needs & Objectives, Limitations to Advice, Assumptions made and Impacts of Advice.",
  };

  return (
    <>
      <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
        <Container text>
          <Header inverted as="h1">
            New Business Checklist
            <Header.Subheader>To ensure compliance with our Advice Process, this form must be completed for all new clients of DB.</Header.Subheader>
          </Header>
        </Container>
      </Segment>
      <Container text style={{ padding: "1em 0em" }}>
        <Form
          loading={loading}
          onSubmit={formik.handleSubmit}
          success={formState === "success"}
          error={formState === "error"}
        >
          <Form.Field required>
            <label>Client Name Search</label>
            <Dropdown
              placeholder="Search client here"
              search
              selection
              className="dropdown-custom"
              error={getError(GENERAL_QUESTIONS.CLIENT)}
              helperText={getErrorMessage(GENERAL_QUESTIONS.CLIENT)}
              options={clients.map((client) => ({
                key: client[clientSchema.id],
                value: client,
                text: `${client[clientSchema.client_name]} | Pipedrive: ${
                  client[clientSchema.pipedrive_id]
                }`,
              }))}
              value={formik.values[GENERAL_QUESTIONS.CLIENT]}
              onSearchChange={onSearchFetch}
              onChange={(e, { value }) => {
                handleClientChange(value);
              }}
            />
          </Form.Field>
          <label
            style={{
              width: "100%",
              display: "block",
              margin: " 0 0 .28571429rem 0",
              color: "rgba(0,0,0,.87)",
              fontSize: ".92857143em",
              fontWeight: 700,
              textTransform: "none",
            }}
          >
            Select Specific Folder (optional)
          </label>
          <Form.Field style={{ width: "100%" }}>
            <Dropdown
              disabled={foldersRetrieved.length === 0}
              loading={loadingFolders}
              placeholder={
                loadingFolders
                  ? "Loading folders ..."
                  : foldersRetrieved.length === 0
                  ? "No subfolders found"
                  : "Pick a folder"
              }
              clearable
              selection
              value={formik.values[SELECTED_FOLDER]}
              options={foldersRetrieved.map((folder) => ({
                key: folder,
                value: folder,
                text: folder,
              }))}
              onChange={(e, { value }) =>
                formik.setFieldValue(SELECTED_FOLDER, value)
              }
              // error={!isValidFolder}
            />
          </Form.Field>
          <Form.Field required>
            <label>
              <Popup
                content="Select specific pipedrive deal"
                trigger={<Icon name="info circle" />}
                position="top center"
              />
              Pipedrive Deal
            </label>
            <Dropdown
              loading={loadingDeals}
              placeholder={"Please select a client to get deals"}
              selection
              clearable
              disabled={deals.length === 0}
              className="dropdown-custom"
              options={deals.map((deal) => ({
                key: deal.id,
                value: deal,
                text: `${deal.id} | ${deal.title}`,
              }))}
              value={formik.values[SELECTED_DEAL]}
              onChange={(e, { value }) => {
                console.log('here is the selected deal: ', value);
                formik.setFieldValue(SELECTED_DEAL, value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Completed By</label>
            <Dropdown
              loading={loadingAdvisers}
              placeholder={
                advisersList.length === 0
                  ? "There are no users available"
                  : "Search for a user"
              }
              selection
              search
              className="dropdown-custom"
              options={advisersList.map((adviser) => ({
                key: adviser.id,
                value: adviser,
                text: adviser.name,
              }))}
              value={formik.values[COMPLETED_BY]}
              onChange={(e, { value }) =>
                formik.setFieldValue(COMPLETED_BY, value)
              }
              // onClick={onSearchAdvisers}
            />
          </Form.Field>
          <Form.Field>
            <label>Risk Adviser</label>
            <Dropdown
              loading={loadingAdvisers}
              placeholder={
                advisersList.length === 0
                  ? "There are no users available"
                  : "Search for a user"
              }
              selection
              search
              className="dropdown-custom"
              options={advisersList.map((adviser) => ({
                key: adviser.id,
                value: adviser,
                text: adviser.name,
              }))}
              value={formik.values[RISK_ADVISER]}
              onChange={(e, { value }) =>
                formik.setFieldValue(RISK_ADVISER, value)
              }
              // onClick={onSearchAdvisers}
            />
          </Form.Field>
          <Form.Field>
            <label>Select Type</label>
            <Dropdown
              placeholder="Select Type"
              fluid
              selection
              options={[
                { key: WHOLESALE, text: "Wholesale", value: WHOLESALE },
                { key: RETAIL, text: "Retail", value: RETAIL },
              ]}
              value={formik.values[GENERAL_QUESTIONS.TYPE]}
              onChange={(e, { value }) => {
                handleTypeChange(value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Date</label>
            <SemanticDatepicker
              onChange={onChange}
              value={formik.values[GENERAL_QUESTIONS.DATE]}
            />
          </Form.Field>
          <Segment style={{ margin: "1.5em 0em" }}>
            <Header as="h5">Checklist:</Header>
            {formik.values.type === WHOLESALE && (
              <>
                {COMPLETE_WHOLESALE.map((fieldName) => (
                  <Form.Group key={fieldName}>
                    <Form.Field>
                      <Checkbox
                        label={LABELS[fieldName]}
                        name={fieldName}
                        checked={formik.values[fieldName] || false}
                        onChange={() => {
                          console.log("field name: ", fieldName);
                          formik.setFieldValue(
                            fieldName,
                            !formik.values[fieldName]
                          );
                          if (fieldName === "optRetail") {
                            formik.setFieldValue(
                              [GENERAL_QUESTIONS.TYPE],
                              "retail"
                            );
                          }
                        }}
                      />
                    </Form.Field>
                    {TOOLTIP_MESSAGES[fieldName] && (
                      <Popup
                        content={TOOLTIP_MESSAGES[fieldName]}
                        trigger={<Icon name="info circle" />}
                        position="top center"
                      />
                    )}
                  </Form.Group>
                ))}
              </>
            )}
            {formik.values.type === RETAIL && (
              <>
                {COMPLETE_RETAIL.map((fieldName) => (
                  <Form.Group key={fieldName}>
                    <Form.Field>
                      <Checkbox
                        label={LABELS[fieldName]}
                        name={fieldName}
                        checked={formik.values[fieldName] || false}
                        onChange={() =>
                          formik.setFieldValue(
                            fieldName,
                            !formik.values[fieldName]
                          )
                        }
                      />
                    </Form.Field>
                    {TOOLTIP_MESSAGES[fieldName] && (
                      <Popup
                        content={TOOLTIP_MESSAGES[fieldName]}
                        trigger={<Icon name="info circle" />}
                        position="top center"
                      />
                    )}
                  </Form.Group>
                ))}
              </>
            )}
          </Segment>
          <Form.TextArea
            label="Notes"
            placeholder="Enter any other information"
            name="otherInfo"
            value={formik.values[GENERAL_QUESTIONS.NOTES]}
            onChange={(e) => {
              formik.setFieldValue(GENERAL_QUESTIONS.NOTES, e.target.value);
            }}
          />
          <Message success content={successMessage} />
          <Message error content={errorMessage} />
          <Button
            fluid
            type="button"
            content="Save"
            color="green"
            onClick={handleSave}
            style={{ margin: "2em 0em 0.5em" }}
          />
          <Button fluid basic content="Submit" />
          {formik.touched && formik.errors && (
            <Message error>Missing Required fields</Message>
          )}
        </Form>
      </Container>
    </>
  );
};

export default NewBusinessChecklistForm;
